import React, { Suspense, useEffect, useRef } from 'react'
import Utils from '../../../lib/Utils'
import LoadingComponent from '../LoadingComponent'

/**
 * 
 * @param {object} props The props object
 * @returns 
 */
const ResourcesView = ({lesson}) => {
    console.log('{ResourcesView}')
    //console.log('{ResourcesView}: lesson=',lesson)
    const _lesson = useRef({lessonId: lesson.lesson.lessonId, courseId: lesson.lesson.courseId})
    const UserResView = {
        Student: 'StudentResourcesView',
        Teacher: 'TeacherResourcesView'
    }
    const user = lesson.lesson.user.userType
    const ResView = UserResView[user]??'TeacherResourcesView'
    const ResourcesView = React.lazy(() => import(`../${ResView}`))
    useEffect(() => {
        Utils.addAnalytics({
            page: `MEEResources.${user}Resources`,
            url: window.location.href,
            title: `NS.MEEResources.${user}Resources`,
            lessonId: _lesson.current.lessonId,
            courseId: _lesson.current.courseId
        })
    },[])
    return (
        <>
        <div className={`animate__animated animate__fadeIn vnvhfhfjdgf w-100 h-centred jshs-oeoe`}>
            <Suspense fallback={<LoadingComponent />}>
                <ResourcesView lesson={lesson} />
            </Suspense>
        </div>
        </>
    )
}

export default ResourcesView;