import React, { useState, useEffect } from 'react';
import AccountHeader from './tools/AccountHeader';
import ActionModal2 from './tools/ActionModal2';
import Loader from './tools/Loader';
import RightSidePopin from './tools/RightSidePopin';
import Error from './tools/Error';
import StateUpdators from '../lib/StateUpdators';
import Utils from '../lib/Utils';
import ReportError from './tools/ReportError';
import LoadingComponent from './tools/LoadingComponent';

/**
 * Settings
 * @returns 
 */
const Settings = () => {
    console.log('{Settings}')
    const [Content, setSettingsPageContent] = useState({
        C: () => <LoadingComponent />
    })
    StateUpdators.setSettingsPageContent = setSettingsPageContent
    useEffect(() => {
        Utils.addAnalytics({
            page: 'Settings',
            url: window.location.href,
            title: `NS.Settings`
        })
        Utils.setPageTitle('Settings')
        Utils.fetchSettingsInfo()
    },[]);
    return (
        <>
        <AccountHeader />
        <Content.C />
        <ActionModal2 />
        <Error />
        <ReportError />
        <Loader />
        <RightSidePopin />
        </>
    )
}

export default Settings;