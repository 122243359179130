import React, { Suspense, useEffect, useState } from 'react';
import Separator from './Separator';
import StateUpdators from '../../lib/StateUpdators';
import Utils from '../../lib/Utils';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Texts from '../../lib/Texts';
import Assets from '../../lib/Assets';
import LoadingComponent from './LoadingComponent';

/**
 * Digital PINS (view) for teachers
 * @returns 
 */
const DigitalPINSView = () => {
    console.log('{DigitalPINSView}');
    const [C, setPINSContent] = useState({
        C: () => <LoadingComponent />
    })
    const [C2, setPINSContent2] = useState({ 
        C: () => <></>
    })
    StateUpdators.setPINSContent = setPINSContent;
    StateUpdators.setPINSContent2 = setPINSContent2;
    useEffect(() => {
        Utils.fetchCurriculumsForPINSView();
    },[]);
    return (
        <>
        <Separator styles={{height: '10px'}} />
        <div className='docs-c4k fhfh-edit box-b animate__animatted animate__fadeIn width-90 centred rounded wt-bkg'>
            <div className='jdjdh-kfjdh'>
                <div className='jfhdgd-kfkfj w-40 centered'>
                    <C.C />
                </div>
                <Separator styles={{height: '10px'}} />
                <C2.C />
            </div>
        </div>
        </>
    )
}

export default DigitalPINSView;

/**
 * Digital PINS view
 */
const _PINSViews = {
    /**
     * Minecraft Curriculum Digital PINS View
     * @returns 
     */
    MEE: () => {
        const MEEPINSView = React.lazy(() => import('./curriculum_pins_views/MEE'))
        return (
            <>
            <Suspense fallback={<LoadingComponent />}>
                <MEEPINSView />
            </Suspense>
            </>
        )
    },
    /**
     * Coding & Robotics Curriculum Digital PINS View
     */
    CRC: () => {
        const CRCPINSView = React.lazy(() => import('./curriculum_pins_views/CRC'))
        return (
            <>
            <Suspense fallback={<LoadingComponent />}>
                <CRCPINSView />
            </Suspense>
            </>
        )
    },
    /**
     * Digital Skills Curriculum Digital PINS View
     * @returns 
     */
    DSC: () => {
        const DSCPINSView = React.lazy(() => import('./curriculum_pins_views/DSC'))
        return (
            <>
            <Suspense fallback={<LoadingComponent />}>
                <DSCPINSView />
            </Suspense>
            </>
        )
    },
    Default: () => 
    <>
    <div className='animate__animted animate__fadeIn hsgsg-kdjd'>
        <img src={Assets.icons.error} className='jshsld-lepejnms' alt='Warning' />
        <p>{Texts.noPINSCurr}</p>
    </div>
    </>
}


/**
 * When we have curriculums, display this
 * @returns 
 */
const CurriculumsAvailable = ({curriculums}) => {
    const [curriculum, setCurriculum] = useState(curriculums[0]);
    const [_curriculums, setCcurriculumsCurriculumsForPINSView] = useState(curriculums);
    StateUpdators.setCcurriculumsCurriculumsForPINSView = setCcurriculumsCurriculumsForPINSView;
    const PINSView = _PINSViews[curriculum.extra.shortName]??_PINSViews.Default;
    useEffect(() => {
        StateUpdators.setPINSContent2({
            C: () => <PINSView />
        })
    },[curriculum])
    return (
        <>
        <FormControl fullWidth>
            <InputLabel id='curr-digitial-pins-select-label'>Curriculum:</InputLabel>
            <Select
                labelId='curr-digitial-pins-select-label'
                id='demo-simple-select'
                value={JSON.stringify(curriculum)}
                label={curriculum.title}
                onChange={(e) => {
                    setCurriculum(JSON.parse(e.target.value));
                }}
            >
                {
                    Object.values(_curriculums).map((el,i) => {
                        return (
                            <MenuItem value={JSON.stringify(el)} key={i}>{el.title}</MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
        </>
    )
}

export {_PINSViews,CurriculumsAvailable};