import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Utils from '../../lib/Utils';
import AccountHeader from './AccountHeader';
import StudentProgress from './StudentProgress';
import C4KLogoLeftBottom from './C4KLogoLeftBottom';
import ProgressBadge from './ProgressBadge';
import ActionModal from './ActionModal';
import RightSidePopin from './RightSidePopin';
import TaskPageItemsHTML from './TaskPageItemsHTML';
import FullScreenModal from './FullScreenModal';
import MiniMizedView from './MinimizedView';
import LessonTaskPageModal from './LessonTaskPageModal';
import SideFeedback from './SideFeedback';
import StateUpdators from '../../lib/StateUpdators';
import ElearnerLeftBottom from './ElearnerLeftBottom';
import PoweredByC4KLogoRightBottom from './PoweredByC4KLogoRightBottom';

/**
 * Render a given lesson
 * @param {object} props The props object which contains the content props with lessons and type props
 * @returns 
 */
const RenderLessonNewLook = (props) => {
    console.log('{RenderLessonNewLook}')
    //console.log('{RenderLessonNewLook}: props=',props)
    const tokenData = JSON.parse(Utils.base64Decode(useParams().token))
    const _tokenData = useRef(tokenData)
    const isPaceAligned = props.content.lesson.PACE.status;
    const isCambProgAligned = props.content.lesson.CambridgeProgram.status
    const extraInfo = Utils.extras[_tokenData.current.token.extra.shortName]??false
    const ExtraInfoView = extraInfo && extraInfo.showExtraSummaryInfo() ? extraInfo.extraInfo : () => <></>
    const [_courseTitle, setCourseTitle] = useState(props.content.courseTitle)
    StateUpdators.setCourseTitle = setCourseTitle
    const [_paceAligned, setPaceAlignment] = useState({
        aligned: isPaceAligned,
        info: {
            code: isPaceAligned && props.content.lesson.PACE.code,
            subjects: [isPaceAligned && props.content.lesson.PACE.subjects.split(',')],
            charTraits: {
                trait: isPaceAligned && props.content.lesson.PACE.charTraits.trait,
                aboutTrait: isPaceAligned && props.content.lesson.PACE.charTraits.aboutTrait,
                bibleVerse: isPaceAligned && props.content.lesson.PACE.charTraits.bibleVerse
            }
        }
    })
    const [_cambridgeProg, setCambridgeProgram] = useState({
        aligned: isCambProgAligned,
        info: props.content.lesson.CambridgeProgram
    })
    StateUpdators.setPaceAlignment = setPaceAlignment
    StateUpdators.setCambridgeProgram = setCambridgeProgram
    const paceAlignment = useRef(isPaceAligned)
    const noStudentGroup = useRef(isPaceAligned?props.content.lesson.PACE.charTraits.noStudentGroup:null)
    const timer = useRef(null)
    useEffect(() => {
        Utils.addAnalytics({
            page: 'Lesson',
            url: window.location.href,
            title: `NS.Lesson.ClassSession`,
            courseId: props.content.lesson.courseId,
            lessonId: props.content.lesson.lessonId
        })
        Utils.showStudentProgress({..._tokenData.current})
        Utils.showStudentProgresBadge({..._tokenData.current})
        Utils.cacheLesson({title: props.content.lesson.lessonTitle})
        Utils.cacheLessonInfo({...props.content.lesson})
        Utils.setPageTitleFull({title: `Computers 4 Kids | Classesonline > ${props.content.courseTitle} > ${props.content.lesson.number}. ${props.content.lesson.lessonTitle}`})
        Utils.fetchUserDetails({courseId: props.content.lesson.courseId})
        if(paceAlignment.current) {
            if(noStudentGroup.current) {
                timer.current = Utils.showNoStudentGroupNotice({token: _tokenData.current.token})
            }
        }
        return () => clearTimeout(timer.current)
    },[props.content])
    return (
        <>
        <div className='animate__animated animate__fadeIn lesson-mfhdh-new'>
            <AccountHeader showActivitiesLink={true} showLessonsLink={true} />
            <div className='btm-section-ghhf'>
                <div className='gfgdg-hdr hdgd-tytb-top w-60 centred'>
                    <p className='hhg-ewr3s'><u>{props.content.curriculumTitle}</u></p>
                    <h2 className='jdhd-hfg'><em dangerouslySetInnerHTML={{__html: _courseTitle}}></em></h2>
                    <p className='hhg-ewr3s kjf-kf' dangerouslySetInnerHTML={{__html: `${props.content.lesson.number}. ${props.content.lesson.lessonTitle}`}}></p>
                </div>
                <br />
                <div className='task-summ-hfhfh rounded shadow w-60 centred box-b'>
                    <h2 className='mcjd0-eke'>Task Summary</h2>
                    <p className='kdjs-epe' dangerouslySetInnerHTML={{__html: props.content.lesson.activity || props.content.lesson.intro}}></p>
                    <ExtraInfoView subjects={props.content.lesson.extra.themes} />
                </div>
                <div className='the-task-page-items w-80 centred'>
                    <TaskPageItemsHTML info={{
                        lessonOutcome: props.content.lesson.lessonOutcome,
                        lessonOutcomeTitle: 'Lesson Outcome',
                        ...props.content,
                        _paceAligned: _paceAligned,
                        _cambridgeProg: _cambridgeProg
                    }}
                    token={_tokenData.current} />
                </div>
            </div>
        </div>
        <ProgressBadge />
        <C4KLogoLeftBottom />
        <ElearnerLeftBottom />
        <PoweredByC4KLogoRightBottom />
        <StudentProgress />
        <ActionModal />
        <LessonTaskPageModal />
        <FullScreenModal />
        <RightSidePopin />
        <SideFeedback />
        <MiniMizedView />
        </>
    )
}

export default RenderLessonNewLook;