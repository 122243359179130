import React, { useEffect, useRef, useState } from 'react';
import Utils from '../../lib/Utils';
import StateUpdators from '../../lib/StateUpdators';
import { Button, FormControl,TextField, InputAdornment, IconButton, InputLabel, Select, MenuItem } from '@mui/material';
import { Visibility, VisibilityOff, Close } from '@mui/icons-material';
import Separator from './Separator';
import CountriesMUIVersion from '../../lib/CountriesMUIVersion';
import TermsAgreeTickBox from './TermsAgreeTickBox';
import Texts from '../../lib/Texts';

/**
 * The SchoolRegFormView form
 * @returns 
 */
const SchoolRegFormView = () => {
    console.log('{SchoolRegFormView}');
    const [showPassword, setShowPassword] = useState(false);
    const __userTypes = {
        School: 'School'
    };
    const _texts = useRef({
        HomeSchooler: 'Awesome! You will join us as an <u>Individual</u>.',
        School: 'Awesome! You will join us as a <u>School</u>.',
        Orginazation: 'Awesome! You will join us as an <u>Organization</u>.',
        Licensee: 'Awesome! You will join us as a <u>Licensee</u>.'
    })
    const [_input, setInput] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        password2: '',
        schoolName: '',
        location: {
            area: '',
            provState: '',
            postalAddress: ''
        },
        role: ''
    });
    const [error, setError] = useState({
        firstname: false,
        lastname: false,
        email: false,
        password: false,
        password2: false,
        schoolName: false,
        msg: '',
        role: false
    });
    const [role, setRole] = useState('');
    const _userRoles = [
        {
            title: 'Teacher/Facilitator',
            userType: 'Teacher',
        },
        {
            title: 'Principal/Manager/Burser',
            userType: 'Teacher',
        },
        {
            title: 'Head Teacher',
            userType: 'Teacher',
        }
    ]
    //console.log('role=',role)
    useEffect(() => {
        StateUpdators.setHeader('Now, please tell us who you are:');
        StateUpdators.setHeader1(_texts.current[Utils.chosenOption]??'Awesome!, Let us proceed...!')
    },[]);
    return (
        <>
        <div className='hshs09jdhd hshs09jdhd-oso box-b animate__animated animate__fadeInRight w-90 white-bkg shadow rounded centred'>
            <div className='ksjs-peoe ptr' title='Close/Dismiss'>
                <Close sx={{height: 60}} onClick={() => {
                    Utils.resetRegView()
                }} />
            </div>
            <div className='ksks-prpslsks w-90 centred'>
                <div className='hoverrable-input'>
                    <FormControl fullWidth>
                        <TextField
                            id='outlined-basic'
                            label='School Name'
                            variant='outlined'
                            placeholder='Enter school name'
                            error={error.schoolName}
                            onFocus={() => {
                                setError({...error,schoolName: false})
                            }}
                            onBlur={ async () => {
                                if(!_input.schoolName) return
                                const results = await Utils.checkExistingInstitution({institutionName: _input.schoolName});
                                if(results.status) {
                                    if(results.exists) {
                                        const _error = error;
                                        _error.schoolName = true;
                                        _error.msg = Texts.errors.instExists;
                                        setError(_error)
                                    } else {
                                        const _error = error;
                                        _error.schoolName = false;
                                        _error.msg = '';
                                        setError(_error)
                                    }
                                } else {
                                    const _error = error;
                                    _error.schoolName = true;
                                    _error.msg = '';
                                    setError(_error)
                                }
                            }}
                            value={_input.schoolName} onChange={(e) => {
                                setInput({
                                    ..._input,
                                    schoolName: e.currentTarget.value
                                })
                            }}
                        />
                        {
                            error.schoolName ? <span className='error'>{error.msg}</span>:<></>
                        }
                        <Separator styles={{height: '10px'}} />
                        <TextField
                            id='outlined-basic'
                            label='Firstname'
                            variant='outlined'
                            placeholder='Enter your firstname'
                            error={error.firstname}
                            onFocus={() => {
                                setError({...error,firstname: false})
                            }}
                            value={_input.firstname} onChange={(e) => {
                                setInput({
                                    ..._input,
                                    firstname: e.currentTarget.value
                                })
                            }}
                        />
                        <Separator styles={{height: '10px'}} />
                        <TextField
                            id='outlined-basic'
                            label='Lastname'
                            variant='outlined'
                            placeholder='Enter your lastname'
                            error={error.lastname}
                            onFocus={() => {
                                setError({...error,lastname: false})
                            }}
                            value={_input.lastname} onChange={(e) => {
                                setInput({
                                    ..._input,
                                    lastname: e.currentTarget.value
                                })
                            }}
                        />
                        <Separator styles={{height: '20px'}} />
                        <TextField
                            id='outlined-basic'
                            label='Email Address'
                            variant='outlined'
                            placeholder='Enter your email address'
                            error={error.email}
                            onFocus={() => {
                                setError({...error,email: false})
                            }}
                            onBlur={ async () => {
                                if(!_input.email) return
                                const results = await Utils._checkExistingEmail({email: _input.email});
                                if(results.status) {
                                    if(results.exists) {
                                        const _error = error;
                                        _error.email = true;
                                        _error.msg = Texts.errors.emailExists;
                                        setError(_error)
                                    } else {
                                        const _error = error;
                                        _error.email = false;
                                        _error.msg = '';
                                        setError(_error)
                                    }
                                } else {
                                    const _error = error;
                                    _error.email = true;
                                    _error.msg = '';
                                    setError(_error)
                                }
                            }}
                            value={_input.email} onChange={(e) => {
                                setInput({
                                    ..._input,
                                    email: e.currentTarget.value
                                })
                            }}
                        />
                        {
                            error.email ? <span className='error'>{error.msg}</span>:<></>
                        }
                        <Separator style={{height: '20px'}} />
                        <TextField
                            id='outlined-basic'
                            label='Set Password'
                            variant='outlined'
                            placeholder='Set your password'
                            aria-label='Toggle password visibility'
                            error={error.password}
                            onFocus={() => {
                                setError({...error,password: false})
                            }}
                            type={showPassword ? 'text' : 'password'}
                            value={_input.password} onChange={(e) => {
                                setInput({
                                    ..._input,
                                    password: e.currentTarget.value
                                })
                            }}
                            InputProps={{
                                endAdornment: 
                                <InputAdornment position='end'>
                                    <IconButton onClick={() => {
                                        setShowPassword((show) => !show);
                                    }}>{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
                                </InputAdornment>
                            }}
                        />
                        <Separator styles={{height: '20px'}} />
                        <TextField
                            id='outlined-basic'
                            label='Confirm Password'
                            variant='outlined'
                            aria-label='Toggle password visibility'
                            placeholder='Re-enter your password'
                            error={error.password2}
                            type={showPassword ? 'text' : 'password'}
                            onFocus={() => {
                                setError({...error,password2: false})
                            }}
                            value={_input.password2} onChange={(e) => {
                                setInput({
                                    ..._input,
                                    password2: e.currentTarget.value
                                })
                            }}
                            InputProps={{
                                endAdornment: 
                                <InputAdornment position='end'>
                                    <IconButton onClick={() => {
                                        setShowPassword((show) => !show);
                                    }}>{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
                                </InputAdornment>
                            }}
                        />
                        <Separator styles={{height: '20px'}} />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id='demo-simple-select-label'>Your Role on this School:</InputLabel>
                        <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={role?JSON.stringify(role):role}
                            label={'Your Role on this School:'}
                            onChange={(e) => {
                                setRole({
                                    ...JSON.parse(e.target.value)
                                })
                                setError({...error, role: false});
                            }}
                            error={error.role}
                        >
                            {
                                _userRoles.map((role,i) => {
                                    return (
                                        <MenuItem value={JSON.stringify(role)} key={i}>{role.title}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
                
                <Separator styles={{height: '20px'}} />

                <CountriesMUIVersion defaultCountry={{
                    countryId: 155,
                    dialCode: '+27'
                }}  />
                            
                <Separator style={{height: '20px'}} />

                <TermsAgreeTickBox />

                <div className='btn-begin jjfhfhf w-80 centred'>
                    <Button title='Continue' variant='contained' className='force-round-btn' onClick={() => {
                        if(!_input.schoolName) {
                            Utils.showFeedbackError({msg: 'Plase enter the school name'});
                            setError({...error, schoolName: true});
                            return;
                        } else {
                            setError({...error, schoolName: false});
                        }
                        if(!_input.firstname) {
                            Utils.showFeedbackError({msg: 'Plase enter your firstname'});
                            setError({...error, firstname: true});
                            return;
                        } else {
                            setError({...error, firstname: false});
                        }
                        if(!_input.lastname) {
                            Utils.showFeedbackError({msg: 'Plase enter your lastname'});
                            setError({...error, lastname: true});
                            return;
                        } else {
                            setError({...error, lastname: false});
                        }
                        if(!_input.email) {
                            Utils.showFeedbackError({msg: 'Plase enter your email address'});
                            setError({...error, email: true});
                            return;
                        } else {
                            setError({...error, email: false});
                        }
                        if(!Utils.isValidEmail(_input.email)) {
                            Utils.showFeedbackError({msg: 'Plase enter a valid email address'});
                            setError({...error, email: true});
                            return;
                        } else {
                            setError({...error, email: false});
                        }
                        if(!_input.password) {
                            Utils.showFeedbackError({msg: 'Plase set your password'});
                            setError({...error, password: true});
                            return;
                        } else {
                            setError({...error, password: false});
                        }
                        if(!_input.password2) {
                            Utils.showFeedbackError({msg: 'Plase confirm your password'});
                            setError({...error, password2: true});
                            return;
                        } else {
                            setError({...error, password2: false});
                        }
                        if(_input.password !== _input.password2) {
                            Utils.showFeedbackError({msg: 'Passwords do not match'});
                            setError({...error, password: true});
                            return;
                        } else {
                            setError({...error, password: false});
                        }
                        if(!role) {
                            Utils.showFeedbackError({msg: 'Plase select your role on the school'});
                            setError({...error, role: true});
                            return;
                        } else {
                            setError({...error, role: false});
                        }
                        if(!Utils.registrations.checkedTermsCheckBox) {
                            Utils.showFeedbackError({msg: 'Plase agree to our Terms and Conditions'});
                            StateUpdators.setCheckBoxError(true);
                            return;
                        } else {
                            StateUpdators.setCheckBoxError(false);
                        }
                        let allCorrect = true;
                        Object.entries(error).forEach(val => {
                            if(val[1]) {
                                console.log('This is not correct: ',val)
                                allCorrect = false;
                                return
                            }
                        })
                        if(!allCorrect) {
                            Utils.showFeedbackError({msg: 'Plase correct all errors before proceeding.'})
                            return
                        };
                        Utils.chosenOption = 'School'
                        Utils.registerNewUser({
                            ..._input, 
                            country: Utils.registrations.country,
                            countryId: Utils.registrations.country.countryId, 
                            userType: __userTypes[Utils.chosenOption],
                            role: {...role}
                        });
                    }}>
                        Continue
                    </Button>
                </div>
            </div>
        </div>
        </>
    )
}

export default SchoolRegFormView;