import { Box, LinearProgress } from '@mui/material'
import React from 'react'

/**
 * Loading Component
 * @returns 
 */
const LoadingComponent = () => {
    return (
        <>
        <Box sx={{ width: '100%' }}><LinearProgress /></Box>
        </>
    )
}

export default LoadingComponent