import React from 'react'

/**
 * Show subjects
 * @param {array} subjects The subjects 
 * @returns 
 */
const LessonSubjectAlignments = ({subjects}) => {
    console.log('{LessonSubjectAlignments}')
    //console.log('subjects=',subjects)
    return (
        <>
        <div className='animate__animated animate__fadeIn nshs-peoeoi w-60 centred shadow rounded-4'>
            <div className='mjks content-centre rounded-4-left bkg-wt'>
                <p className='mgn-0 lkjs'>Subjects:</p>
            </div>
            <div className='ldksj content-centre rounded-4-right'>
                {
                    subjects.map((sub,i) => {
                        return (
                            <div className='content-centre' key={i}>
                                <p className='mgn-0'> {sub}{(i+1) !== subjects.length && <>,&nbsp;</>}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
        </>
    )
}

export default LessonSubjectAlignments