import React, { Suspense, useEffect, useRef, useState } from 'react'
import Utils from '../../../lib/Utils'
import PopupHandleTitle from '../PopupHandleTitle'
import ResourcesView from './ResourcesView'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import StateUpdators from '../../../lib/StateUpdators';
import LoadingComponent from '../LoadingComponent';

/**
 * 
 * @param {object} props The props object
 * @returns 
 */
const MEEResources = ({lesson}) => {
    console.log('{MEEResources}')
    //console.log('{MEEResources}: lesson=',lesson)
    const _lesson = useRef({lessonId: lesson.lesson.lessonId, courseId: lesson.lesson.courseId})
    const [value, setValue] = React.useState('1')
    const handleChange = (event, newValue) => {
        event = {};
        setValue(newValue)
    };
    const [resTitle, setResourcesTitle] = useState('Teacher Resources')
    StateUpdators.setResourcesTitle = setResourcesTitle
    const SkillsCardsResourcesView = React.lazy(() =>import('../SkillsCardsResourcesView'))
    const CraftingCardsResourcesView = React.lazy(() =>import('../CraftingCardsResourcesView'))
    const OtherResourcesView = React.lazy(() =>import('../OtherMEEResourcesView'))
    useEffect(() => {
        Utils.addAnalytics({
            page: 'MEEResources',
            url: window.location.href,
            title: 'NS.MEEResources',
            lessonId: _lesson.current.lessonId,
            courseId: _lesson.current.courseId
        })
    },[])
    return (
        <>
        <div className='hfgfgf-nvfg-task- w-80 h-centred rounded-small animate__animated animate__fadeIn'>
            <h4 className='jdhd-hfg-i' dangerouslySetInnerHTML={{__html: `${lesson.curriculumTitle} > ${lesson.courseTitle} > ${lesson.subCourseTitle?` ${lesson.subCourseTitle} >`:''} ${lesson.lesson.number}. ${lesson.lesson.lessonTitle}`}}></h4>
            <PopupHandleTitle
                title={'Resources'}
                onLeftIconClick={() => {
                    Utils.closeLessonTaskPageModal();
                }}
                onRightIconClick={() => {}}
                showMinimizeButton={true}
            />
        </div>
        <div className='jshskkd w-80 centred bkg-white rounded shadow'>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label='Resources'>
                    <Tab label={resTitle} value='1' />
                    <Tab label='Skills Cards' value='2' />
                    <Tab label='Crafting Cards' value='3' />
                    <Tab label='Other Resources' value='4' />
                </TabList>
                </Box>
                <TabPanel value='1'>
                    <ResourcesView lesson={lesson} />
                </TabPanel>
                <TabPanel value='2'>
                    <Suspense fallback={<LoadingComponent />}>
                        <SkillsCardsResourcesView lesson={lesson} />
                    </Suspense>
                </TabPanel>
                <TabPanel value='3'>
                    <Suspense fallback={<LoadingComponent />}>
                        <CraftingCardsResourcesView lesson={lesson} />
                    </Suspense>
                </TabPanel>
                <TabPanel value='4'>
                    <Suspense fallback={<LoadingComponent />}>
                        <OtherResourcesView lesson={lesson} />
                    </Suspense>
                </TabPanel>
            </TabContext>
        </div>
        </>
    )
}

export default MEEResources;