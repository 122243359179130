import React, { useEffect, useState } from 'react';
import Assets from '../../lib/Assets';

/**
 * Display an image with a default image while the required image is loading
 * @param {object} props The props object with the `src` (string) of the image and optionally the `alt` prop. Other element attributes can also be given such as `className`, `id`, etc.
 * @returns 
 */
const CustomImage = (props) => {
    //console.log('{CustomImage}: props=',props)
    const [image, setImageSrc] = useState({
        src: Assets.imagePlaceHolder,
        size: {
            width: '150px'
        },
        whenNotLoadedId: props.whenNotLoadedId??''
    })
    //console.log('[CustomImage]',props);
    useEffect(() => {
        const _image = new Image()
        _image.src = props.src
        _image.onload = () => {
            setImageSrc({
                src: props.src,
                size: props.size??{},
                whenNotLoaded: {}
            });
        }
        _image.onerror = (err) => {
            //console.log('Loading image failed: ',err.error??'probably image/file 404.')
            setImageSrc({
                src: Assets.imagePlaceHolderNotLoaded,
                size: props.size??{},
                id: 'not-loaded-nxbsb',
                whenNotLoaded: {}
            });
        }
    },[props.src,props.size])
    return (
        <>
        <img {...{...props,...image.whenNotLoadedId, src: image.src,...image.size, id: image.id??''}} alt={props.alt || ''} />
        </>
    )
}

export default CustomImage;