import React, { useState } from 'react';
import Assets from '../../lib/Assets';
import LoginFormViewLoginPart from './LoginFormViewLoginPart';
import StateUpdators from '../../lib/StateUpdators';


/**
 * The login form
 * @returns 
 */
const LoginFormView = ({reLogin}) => {
    console.log('{LoginFormView}');
    const [Content, setContent] = useState({
        C: () => <LoginFormViewLoginPart reLogin={reLogin} />
    })
    StateUpdators.setLoginFormViewLoginPart = setContent;
    return (
        <>
        <div className='msj-lksjs'>
            <div className='content-centre box-b nsj-peoe'>
                <img alt='Illustration' src={Assets.misc.illustration} className='mdnhs-oels' />
            </div>
            <Content.C />
        </div>
        </>
    )
}

export default LoginFormView;